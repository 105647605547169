<template>
  <VCard class="mb-3">
    <div class="d-flex align-center justify-content-space-between">
      <VCol cols="8" sm="4" lg="3" md="5">
        <VTextField
          dense
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('form.search')"
          v-model="searchString"
          clearable
          :loader-height="5"
          @input="onSearch"
          :loading="searchLoading"
          hide-details
          solo
          @keypress="validationForTitle($event)"
          @paste="validationForTitle($event)"
        />
      </VCol>
      <VCol cols="auto" class="text-right">
        <VBtn
          class="ml-4 success-bg text-white text-transform-none br-10"
          @click="createDialog = true"
          v-if="permissions.can_create_depot"
        >
          <VIcon :left="!$vuetify.breakpoint.smAndDown">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.smAndDown">
            {{ $t("storages.create_storage") }}
          </div>
        </VBtn>
      </VCol>
    </div>

    <VDivider class="mt-3" />

    <v-simple-table class="my-table" :dense="!isLarge">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-if="permissions.can_edit_depot" class="text-center xs">
              <VIcon dense>mdi-cogs</VIcon>
            </th>
            <th class="xs"></th>
            <th class="font-weight-bold">{{ $t("table.name") }}</th>
            <th class="font-weight-bold">{{ $t("form.description") }}</th>
            <th class="font-weight-bold text-center">{{ $t("form.default_deliveries_balance") }}</th>
            <th class="font-weight-bold text-center">{{ $t("form.default_deliveries_negative_limit") }}</th>
          </tr>
        </thead>
        <tbody name="fade" is="transition-group">
          <DepotRow
            v-for="(depot, index) in depots"
            :key="depot.uuid"
            :depot="depot"
            :index="index"
            @delete="onDeleteDepot"
            @toggleRow="toggleRow"
            :class="{ 'active-row': activeRow == depot.uuid }"
          />
        </tbody>
      </template>
    </v-simple-table>
    <h3 v-if="!depots.length">{{$t('table.noData')}}</h3>
    <v-progress-linear v-if="isLoadingFilter" indeterminate color="primary"></v-progress-linear>
    <!-- <div class="text-center mt-3">
      <VBtn
        depressed
        color="blue"
        outlined
        dark
        v-if="!loadMoreDisabled"
        @click="nextPage"
        class="br-10"
      >
        Завантажити більше
      </VBtn>
    </div> -->
    <div
      v-observer="{ nextPage: nextPage }"
      class="observer"
      v-if="!loadMoreDisabled && !searchLoading"
    ></div>

    <CreateDepotDialog
      v-if="permissions.can_create_depot"
      :visible="createDialog"
      @close="createDialog = false"
    />
  </VCard>
</template>

<script>
import language from "../../mixins/language";
import CreateDepotDialog from "./CreateDepotDialog.vue";
import depotService from "../../services/request/depot/depotService";
import notifications from "../../mixins/notifications";
import EventBus from "../../events/EventBus";
import loader from "../../mixins/loader";
import user from "../../mixins/user";
import paginationMixin from "../../mixins/paginationMixin";
import DepotRow from "./DepotRow.vue";
import {uniqueByUUID, validationForTitle} from "../../mixins/helpers";
import SizeUi from "../../mixins/SizeUi";
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

export default {
  name: "DepotsList",
  mixins: [language, notifications, loader, user, paginationMixin, SizeUi],
  components: {
    CreateDepotDialog,
    DepotRow
  },
  data: () => ({
    isLoadingFilter: false,
    activeView: "list",
    searchString: "",
    createDialog: false,
    depots: [],
    addresses: [],
    searchLoading: false,
    loadMoreDisabled: false,
    activeRow: "",
    loadTime: 0
  }),
  methods: {
    validationForTitle,
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    toggleRow(e) {
      this.activeRow = e;
    },
    async onSearch(updated) {
      if (this.searchString) {
        this.page = 1;
        this.depots = await depotService.getDepotsList({
          name: this.searchString,
          uselike: true,
          order: "DESC",
          orderby: "time_created",
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage
        });
      } else {
        const params = {};
        params.offset = updated ? 0 : (this.page - 1) * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage;
        this.depots = await depotService.getDepotsList({
          order: "DESC",
          orderby: "time_created",
          ...params
        });
      }
      this.loadMoreDisabled = this.depots.length < this.perPage;
    },
    async getDepots(params) {
      try {
        this.searchLoading = true;
        const newItems = await depotService.getDepotsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...params,
          order: "DESC",
          orderby: "time_created"
        });
        this.depots.push(...newItems);
        this.depots = uniqueByUUID(this.depots);
        this.loadMoreDisabled = newItems.length < this.perPage;
        this.searchLoading = false;
        this.reorderNumbers();
      } catch (e) {
        this.searchLoading = false;
        this.setLoading(false);
        console.log(e)
      }
    },
    async onDeleteDepot(uuid) {
      try {
        this.page = 1;
        this.setLoading(true);
        await depotService.deleteDepot(uuid);
        this.setLoading(false);
        this.setSuccessNotification("Склад було успішно видалено!");
        this.depots = await depotService.getDepotsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          order: "DESC",
          orderby: "time_created"
        });
        this.loadMoreDisabled = this.depots.length < this.perPage;
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async emitPageChange() {
      try {
        this.reorderNumbers();
        this.isLoadingFilter = true;
        await this.getDepots();
        this.isLoadingFilter = false;
      } catch (e) {
        console.log(e)
        this.isLoadingFilter = false;
      }
    },
    async fetchFirstPage() {
      try {
        this.page = 1;
        this.setLoading(true);
        this.depots = await depotService.getDepotsList({
          limit: this.perPage,
          offset: 0,
          order: "DESC",
          orderby: "time_created"
        });
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    }
  },
  async mounted() {
    EventBus.$on("depot-updated", async () => {
      // await this.fetchFirstPage();
      await this.onSearch(true);
    });
    EventBus.$on("depot-created", async () => {
      // await this.fetchFirstPage();
      await this.onSearch();
    });
    this.setLoading(true);
    await this.getDepots();
    this.setLoading(false);
    this.checkLoadTime();
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    nextPageDisabled() {
      return this.depots.length < this.perPage;
    }
  }
};
</script>

<style lang="scss"></style>
